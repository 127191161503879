import {Fragment} from "react";
import Expenditure from "../components/Accounts/Expenditure";
import TopNavigation from "../components/TopNavigation/TopNavigation";

export default function AccountPage(){


    return(
        <Fragment>
            <TopNavigation />
            <Expenditure/>
        </Fragment>
    );
}