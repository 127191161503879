import {Fragment, useState} from "react";
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {Link, NavLink, Route} from "react-router-dom";
import {useSelector} from "react-redux";
import { Offline, Online } from "react-detect-offline";
export default function TopNavigation(){

    let state=useSelector((state)=> state);



    const isAdmin = () => state.access === 'admin';
    const isAccounts = () => state.access === 'accounts';
    const isSR = () => state.access === 'sr';
    const isSM = () => state.access === 'sm';
    const isCM = () => state.access === 'cm';
    const isProduction = () => state.access === 'production';
    const isPack = () => state.access === 'pack';
    const isStock = () => state.access === 'stock';
    const isHR = () => state.access === 'hr';
    const isRM = () => state.access === 'rm';
    const isWorker = () => state.access === 'worker';
    const isUser = () => state.access === 'user';


    return(

        <Fragment  fluid="true">
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Container>
                    <Navbar.Brand href="#/home">Farco Chemical</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link > <NavLink to="/home">Home</NavLink> </Nav.Link>

                            {isAccounts() &&(
                                <>
                                    <Nav.Link ><NavLink to="/account">Accounts</NavLink></Nav.Link>

                                </> )}
                            {isHR() &&(
                                <>
                                    <NavDropdown title="HR" id="basic-nav-dropdown" >
                                        <NavDropdown.Item >
                                            <Nav.Link > <NavLink to="/employee">Employee</NavLink></Nav.Link>

                                        </NavDropdown.Item>

                                    </NavDropdown>

                                </> )}
                            {isStock() &&(
                                <>
                                    <NavDropdown title="Godown" id="basic-nav-dropdown" >
                                    <NavDropdown.Item><Nav.Link ><NavLink to="/godown">Stock Entry</NavLink></Nav.Link></NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item><Nav.Link ><NavLink to="/delivery">Delivery</NavLink></Nav.Link></NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item><Nav.Link ><NavLink to="/invoice">Invoice</NavLink></Nav.Link></NavDropdown.Item>

                                     </NavDropdown>

                                </>
                            )}
                            {isSR() &&(
                                <>

                                    <NavDropdown title="Marketing" id="basic-nav-dropdown" >
                                        <NavDropdown.Item >
                                            <Nav.Link > <NavLink to="/bazar">New Bazar</NavLink></Nav.Link>

                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item >
                                            <NavLink to="/customer">New Customer</NavLink>

                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item ><NavLink to="/order">New Order</NavLink>

                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item ><NavLink to="/report">New Report</NavLink>

                                        </NavDropdown.Item>
                                    </NavDropdown>

                                </> )}
                            {isSM() &&(
                                <>

                                    <NavDropdown title="Marketing" id="basic-nav-dropdown" >
                                        <NavDropdown.Item >
                                            <Nav.Link ><NavLink to="/sales">Sales Demand</NavLink></Nav.Link>
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item >
                                            <Nav.Link > <NavLink to="/bazar">New Bazar</NavLink></Nav.Link>
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item >
                                            <NavLink to="/customer">New Customer</NavLink>

                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item ><NavLink to="/order">New Order</NavLink>

                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item ><NavLink to="/report">New Report</NavLink>

                                        </NavDropdown.Item>
                                    </NavDropdown>

                                </> )}


                            {isProduction() &&(
                                <>

                                    <NavDropdown title="Production" id="basic-nav-dropdown" >
                                        <NavDropdown.Item >
                                            <Nav.Link > <NavLink to="/cutting">Cutting</NavLink></Nav.Link>

                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item >
                                            <NavLink to="/drying">Drying</NavLink>

                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item >
                                            <NavLink to="/packing">Packaging</NavLink>

                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item >
                                            <NavLink to="/chemical">Chemical</NavLink>

                                        </NavDropdown.Item>

                                    </NavDropdown>
                                </> )}
                            {isPack() &&(
                                <>

                                    <NavDropdown title="Production" id="basic-nav-dropdown" >

                                        <NavDropdown.Item >
                                            <NavLink to="/packing">Packaging</NavLink>

                                        </NavDropdown.Item>


                                    </NavDropdown>
                                </> )}
                            {isCM() &&(
                                <>

                                    <NavDropdown title="Production" id="basic-nav-dropdown" >

                                        <NavDropdown.Item >
                                            <NavLink to="/chemical">Chemical</NavLink>

                                        </NavDropdown.Item>

                                    </NavDropdown>
                                </> )}

                            {isRM() &&(
                                <>

                                    <NavDropdown title="RM" id="basic-nav-dropdown" >
                                        <NavDropdown.Item >
                                            <Nav.Link ><NavLink to="/rm">RM Purchase</NavLink></Nav.Link>

                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item >
                                            <Nav.Link ><NavLink to="/rmrecived">RM_Received</NavLink></Nav.Link>

                                        </NavDropdown.Item>

                                    </NavDropdown>
                                </> )}




                            {isAdmin() &&(
                                <>

                                    <Nav.Link ><NavLink to="/account">Accounts</NavLink></Nav.Link>
                                    <NavDropdown title="Stock" id="basic-nav-dropdown" >
                                        <NavDropdown.Item>
                                            <Nav.Link ><NavLink to="/godown">Stock Entry</NavLink></Nav.Link>

                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item>

                                            <Nav.Link ><NavLink to="/delivery">Delivery</NavLink></Nav.Link>
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item>

                                            <Nav.Link ><NavLink to="/invoice">Invoice</NavLink></Nav.Link>
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                    <NavDropdown title="RM" id="basic-nav-dropdown" >
                                        <NavDropdown.Item >
                                            <Nav.Link ><NavLink to="/rm">RM Purchase</NavLink></Nav.Link>

                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item >
                                            <Nav.Link ><NavLink to="/rmrecived">RM_Received</NavLink></Nav.Link>

                                        </NavDropdown.Item>

                                    </NavDropdown>


                                    <NavDropdown title="Marketing" id="basic-nav-dropdown" >
                                        <NavDropdown.Item >
                                            <Nav.Link > <NavLink to="/bazar">New Bazar</NavLink></Nav.Link>

                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item >
                                            <NavLink to="/customer">New Customer</NavLink>

                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item ><NavLink to="/order">New Order</NavLink>

                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item ><NavLink to="/report">Order Report</NavLink>

                                        </NavDropdown.Item>

                                        <NavDropdown.Divider />
                                        <NavDropdown.Item ><Nav.Link ><NavLink to="/sales">Sales Demand</NavLink></Nav.Link>

                                        </NavDropdown.Item>

                                    </NavDropdown>
                                    <NavDropdown title="Production" id="basic-nav-dropdown" >
                                        <NavDropdown.Item >
                                            <Nav.Link > <NavLink to="/cutting">Cutting</NavLink></Nav.Link>

                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item >
                                            <NavLink to="/drying">Drying</NavLink>

                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item >
                                            <NavLink to="/packing">Packaging</NavLink>

                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item >
                                            <NavLink to="/chemical">Chemical</NavLink>

                                        </NavDropdown.Item>

                                    </NavDropdown>
                                    <NavDropdown title="HR" id="basic-nav-dropdown" >
                                        <NavDropdown.Item >
                                            <Nav.Link > <NavLink to="/employee">Employee</NavLink></Nav.Link>

                                        </NavDropdown.Item>

                                    </NavDropdown>

                                </> )}
                            <Nav.Link ><NavLink to="/profile">Profile</NavLink></Nav.Link>
                            <Nav.Link ><NavLink to="/">Logout</NavLink></Nav.Link>




                        </Nav>

                    </Navbar.Collapse>

                </Container>

                <div className="bg-white m-1 w-50 text-danger">
                    <label className="text-success"> {state.name}: {state.access} :   </label>
                    <Online>Connected</Online>
                    <Offline>Disconnected</Offline>


                </div>
            </Navbar>

        </Fragment>
    );
}