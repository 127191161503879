import {Fragment, useRef} from "react";
import {Button, Col, Nav, Row} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import ReactToPrint from 'react-to-print';
import Table from 'react-bootstrap/Table';
import logo from '../../asset/image/govtLogo.png'
export default function Invoice(){
    const ref=useRef();

    return(
        <Fragment>
            <div className="purchaseLongDiv text-center">
                <Nav className="navItem float-end"><NavLink to="/home"><Button variant="warning" >Close</Button></NavLink></Nav>
                <h3>Invoice management</h3>
                <ReactToPrint  trigger={()=><Button>Print</Button>} content={()=>ref.current}  />
                <hr/>
                <div ref={ref} className="invoice" >

                    <Row className="mt-lg-4 text-center">
                        <Col lg={2}></Col>
                        <Col lg={2} ><img src={logo} alt="govt.logo" width="75" height="75"/></Col>
                        <Col lg={4}><label>গণপ্রজাতন্ত্রী বাংলাদেশ সরকার</label><br/>
                            <label>জাতীয় রাজস্ব বোর্ড</label>
                        </Col>
                        <Col lg={2} ><label className=" ">মূসক-৬.৩</label></Col>
                        <Col lg={2}></Col>
                    </Row>
                    <Row className="text-center">
                        <Col lg={4}></Col>
                        <Col lg={4}>  <label>কর চালানপত্র</label></Col>
                        <Col lg={4}></Col>

                    </Row>
                    <Row className="mt-2">
                        <Col> </Col>
                        <Col className="w-100" ><label className="w-100">[বিধি ৪০ এর উপ-বিধি (১) এ দফা (গ ) ও দফা (চ) দ্রষ্টব্য]</label></Col>
                        <Col ></Col>

                    </Row>
                    <Row className="mt-3 ">
                        <Col lg={5}></Col>
                        <Col lg={4}>
                            <label>নিবন্ধিত ব্যক্তির নাম : </label><label className="text-danger font-weight-bold fs-5"> Heaven international</label><br/>
                            <label>নিবন্ধিত ব্যক্তির বিআইএন:</label><label className="text-danger font-weight-bold fs-5"> 000135426-0103</label><br/>
                            <label>চালানপত্র ইস্যুর ঠিকানা: </label><label className="text-danger font-weight-bold fs-5"> Digirhat, Gobindaganj</label>
                        </Col >
                        <Col lg={3}></Col>

                    </Row>
                    <Row className="mt-3 text-justify">
                        <Col lg={1}></Col>
                        <Col lg={4}>
                            <label>ক্রেতার নাম:</label><br/>
                            <label>ক্রেতার বিআইএন:</label><br/>
                            <label>সরবরাহের গন্তব্যস্থল:</label>
                        </Col>
                        <Col lg={3}></Col>
                        <Col lg={4}>
                            <label>চালানপত্র নম্বর:</label><br/>
                            <label>ইস্যুর তারিখ:</label><br/>
                            <label>ইস্যুর সময়:</label>
                        </Col>
                    </Row>

                   <Row className="m-4">
                       <Table slot="columns"   bordered >
                           <thead >
                           <tr>

                               <th>ক্রমিক নং</th>
                               <th>পণ্যের বর্ননা</th>
                               <th>একক</th>
                               <th>পরিমান</th>
                               <th>একক মূল্য
                               </th>
                               <th>মোট মূল্য
                               </th>
                               <th>সম্পূরক শুল্কের পরিমান
                               </th>
                               <th>মূসকের হার %
                               </th>
                               <th>মূসকের পরিমান</th>
                               <th>সকল প্রকার করসহ মূল্য</th>
                           </tr>
                           </thead>
                           <tbody>


                           <tr className="">
                               <td>1</td>
                               <td>Farco</td>
                               <td>Carton</td>
                               <td>50</td>
                               <td>960</td>
                               <td>455900</td>
                               <td>96000</td>
                               <td>15</td>
                               <td>7600</td>
                               <td>897600</td>


                           </tr>
                           <tr className="">
                               <td>2</td>
                               <td>Farco+</td>
                               <td>Carton</td>
                               <td>50</td>
                               <td>960</td>
                               <td>455900</td>
                               <td>96000</td>
                               <td>15</td>
                               <td>7600</td>
                               <td>897600</td>


                           </tr>


                           <tr className="border-4">




                           </tr>

                           </tbody>
                       </Table>

                   </Row>








                </div>

            </div>

        </Fragment>
    )
}