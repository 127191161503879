import {useSelector} from "react-redux";
import {Fragment, useState} from "react";
import cogoToast from "cogo-toast";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import BootstrapTable from 'react-bootstrap-table-next';
import {Button, Col, Container, Modal, Nav, Row, Table} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import AppUtility from "../../RestAPI/AppUtility";
export default function Order(){


    let state=useSelector((state)=> state);

    const [Upazila, setUpazila]=useState([]);
    const [Union, setUnion]=useState([]);
    const [Bazar, setBazar]=useState([]);
    const [Customer, setCustomer]=useState([]);
    const [Loading, setLoading]=useState(true);
    const [OrderList, setOrderList]=useState();
    const [ID, setID]=useState();
    const [ShopOwner, setShopOwner]=useState();
    const [Mobile, setMobile]=useState();



    const getUpazila=()=>{


        const e = document.getElementById("ddlDistrict");
        const value = e.options[e.selectedIndex].value;
        const dt=state.upazila.filter(x => x.district_id==value);
        setUpazila(dt)
        setUnion([])
        setBazar([])

    }
    const getUnion=()=>{
        const e = document.getElementById("ddlUpazila");
        const value = e.options[e.selectedIndex].value;
        const dt=state.union.filter(x => x.upazila_id==value);
        setUnion(dt)
        setBazar([])
    }
    const getBazar=()=>{
        const e = document.getElementById("ddlUnion");
        const value = e.options[e.selectedIndex].value;
        const dt=state.bazar.filter(x => x.union_id==value);
        setBazar(dt)



    }

    const [show, setShow] = useState(false);


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const getCustomerBy=()=>{

        let district = document.getElementById("ddlDistrict");
        let upazila = document.getElementById("ddlUpazila");
        let union = document.getElementById("ddlUnion");
        let bazar = document.getElementById("ddlBazar");

        let districtName=district.options[district.selectedIndex].text;
        let upazilaName=upazila.options[upazila.selectedIndex].text;
        let unionName=union.options[union.selectedIndex].text;

        let bazarName=bazar.options[bazar.selectedIndex].text;
        let bazarId=bazar.options[bazar.selectedIndex].value;





        let jsonObject=
            {

                "sql":"Select Status,Shop_Owner,Shop_Name,Mobile,id  from Customer where District='"+districtName+"' and Upazila='"+upazilaName+"' and Customer.Union='"+unionName+"' and Bazar='"+bazarName+"' "


            };

        if(district.selectedIndex==0){cogoToast.success('Please Select District');district.focus()}else{
            if(upazila.selectedIndex==0){cogoToast.success('Please Select Upazila');upazila.focus()}else{
                if(union.selectedIndex==0){cogoToast.success('Please Select Union');union.focus()}else{
                    if(bazar.selectedIndex==0){cogoToast.success('Please Select Bazar');bazar.focus()}else{


                        RestClient.PostRequest(AppUrl.getCustomerByDistrict,jsonObject).then(result=>{

                            document.getElementById("tableID").style.visibility="visible";
                            setCustomer(result)

                        });
                    }} } }
    }

    const columns=[
        {dataField: 'Status', text: 'Order',sort:true},
        {dataField: 'Shop_Owner', text:'Owner',sort:true},
        {dataField: 'Shop_Name', text: 'Shop',sort:true},
        {dataField: 'Mobile', text: 'Mobile',sort:true},
        {dataField: 'id', text: 'ID',sort:true},

    ]
    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{

            setID(row['id'])

            setShopOwner(row['Shop_Owner'])
            setMobile(row['Mobile'])


            handleShow();
        }
    }
    const  saveOrder=()=>{

        let bazar = document.getElementById("ddlBazar");
        let bazarName=bazar.options[bazar.selectedIndex].text;
        let bazarId=bazar.options[bazar.selectedIndex].value;

    let beemaQty = document.getElementById("BeemaQty").value;
    let beema_Price = document.getElementById("BeemaPrice").value;
    let beemaPlusQty = document.getElementById("BeemaPlusQty").value;
    let beemaPlus_Price = document.getElementById("BeemaPlus_Price").value;
    let farcoQty = document.getElementById("FarcoQty").value;
    let farco_Price = document.getElementById("Farco_Price").value;
    let farcoPlusQty = document.getElementById("FarcoPlusQty").value;
    let farcoPlus_Price = document.getElementById("FarcoPlus_Price").value;
    let Comments = document.getElementById("txtComments").value;

    let Price=parseInt(beema_Price)+parseInt(beemaPlus_Price)+parseInt(farco_Price)+parseInt(farcoPlus_Price);




    let jsonObject=
        {

            "sql":"INSERT INTO `Order` (`id`, `Customer_Id`, `Customer_Name`, `Bazar_Id`, `Bazar_Name`, `Order_Date`, `Status`, `Beema`, `Beema_Price`, `BeemaPlus`, `BeemaPlus_Price`, `Farco`, `Farco_Price`, `FarcoPlus`, `FarcoPlus_Price`,`Comments`, `Sr_Name`)" +
                " VALUES (NULL, '"+ID+"', '"+ShopOwner+"', '"+bazarId+"', '"+bazarName+"', current_timestamp(), '1', '"+beemaQty+"', '"+beema_Price+"', '"+beemaPlusQty+"', '"+beemaPlus_Price+"', '"+farcoQty+"', '"+farco_Price+"', '"+farcoPlusQty+"', '"+farcoPlus_Price+"','"+Comments+"', '"+state.name+"') "


        };

    if(beemaQty==""){cogoToast.error("Please enter Beema Qty")}else{
        if(beema_Price==""){cogoToast.error("Please enter Beema Price")}else{
            if(beemaPlusQty==""){cogoToast.error("Please enter Beema Plus Qty")}else{
                if(beemaPlus_Price==""){cogoToast.error("Please enter Beema Pluse Price")}else{
                    if(farcoQty==""){cogoToast.error("Please enter Farco Qty")}else{
                        if(farco_Price==""){cogoToast.error("Please enter Farco  Price")}else{
                            if(farcoPlusQty==""){cogoToast.error("Please enter Farco Plus Qty")}else{
                                if(farcoPlus_Price==""){cogoToast.error("Please enter Farco Plus Price")}else{

                                    RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{

                                       if(result==1){

                                           RestClient.PostRequest(AppUrl.customerUpdate,{"sql":"Update Customer set Status='Yes' where id='"+ID+"'"}).then(result=>{

                                               if(result==1){


                                                   let sms="Dear "+ShopOwner+" আপনার Order টি গ্রহন করা হয়েছে "+" 1.Beema: "+beemaQty+" 2.Beema+: "+beemaPlusQty+" 3.Farco: "+farcoQty+" 4.Farco+: "+farcoPlusQty+" Total Price: "+Price+" Taka."+" যে কোন প্রয়োজনে যোগাযোগ করুন-01737131813"+" SR: "+state.name+" -Order Date:"
                                                    AppUtility.sendSMS(sms,Mobile)
                                                   cogoToast.success("Data Saved successfully")
                                               }else{cogoToast.error("Data not saved")}


                                           });

                                       }else{cogoToast.error("Data not saved")}
                                        //alert("Order Saved successfully");
                                        handleClose();

                                    });
                                }
                            }
                        }
                    }
                }
            }
        }
    }





}






    return (

        <Fragment>
            <Container className="animated zoomIn">
                <div className="purchaseDiv text-center">
                    <Nav.Link className="navItem "><NavLink to="/home"><Button variant="warning" className="float-end">Close</Button></NavLink></Nav.Link>

                    <h3>New Order  Entry Form</h3>
                    <hr/>

                    <Container>
                        <Row>
                            <Col lg={3} md={3} sm={12}>
                                <Table>
                                    <tr>
                                        <td>
                                            <select className="float-start w-100 customfontsize" id="ddlDistrict"  onChange={getUpazila} >
                                                <option value="0">-Select District-</option>

                                                {


                                                    state.district.map((ctr,index)=>{
                                                        return (
                                                            <option key={index} value={ctr.id}>{ctr.name}</option>
                                                        )
                                                    })

                                                }


                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <select className="float-start w-100 customfontsize" id="ddlUpazila" onChange={getUnion}>
                                                <option value="0">-Select Upazila-</option>

                                                {
                                                    Upazila.map((ctr,index)=>{
                                                        return (
                                                            <option key={index} value={ctr.id}>{ctr.name}</option>
                                                        )
                                                    })
                                                }


                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <select className="float-start w-100 customfontsize" id="ddlUnion"  onChange={getBazar} >
                                                <option>-Select Union-</option>

                                                {

                                                    Union.map((ctr,index)=>{
                                                        return (
                                                            <option key={index} value={ctr.id}>{ctr.name}</option>
                                                        )
                                                    })

                                                }


                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <select className="float-start w-100 customfontsize" id="ddlBazar"  >
                                                <option>-Select Bazar-</option>

                                                {

                                                    Bazar.map((ctr,index)=>{
                                                        return (
                                                            <option key={index} value={ctr.id}>{ctr.name}</option>
                                                        )
                                                    })

                                                }


                                            </select>
                                        </td>
                                    </tr>

                                </Table>

                            </Col>
                            <Col lg={3}>


                                <Row >
                                    <Col lg={6} md={6} sm={12}><Button onClick={getCustomerBy} variant="success" className="w-100 mt-4">Search</Button></Col>

                                </Row>

                            </Col>


                        </Row>

                    </Container>


                </div>

                <div id="tableID" className="overflow-scroll tableDivBorder">
                    <div className="tableDiv">

                        <BootstrapTable
                            keyField='id'
                            data={Customer}
                            columns={ columns }
                            selectRow={selectRow}

                        >
                        </BootstrapTable>
                    </div>
                </div>


            </Container>


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title> Owner:--{ShopOwner}</Modal.Title>


                </Modal.Header>
                <Modal.Body>

                    <h3 className="mt-3 text-danger">Beema</h3>
                    <label>Qty-: </label>
                    <input id="BeemaQty" type="text" className=" w-25"/>  <label>Price-: </label><input id="BeemaPrice" type="text" className=" w-25"/><label>Tk </label>
                    <h3 className="mt-3 text-danger">Beema+</h3>
                    <label>Qty-: </label>
                    <input id="BeemaPlusQty" type="text" className=" w-25"/>  <label>Price-: </label><input id="BeemaPlus_Price" type="text" className=" w-25"/><label>Tk </label>
                    <h3 className="mt-3 text-danger">Farco</h3>
                    <label>Qty-: </label>
                    <input id="FarcoQty" type="text" className=" w-25"/> <label>Price-: </label><input id="Farco_Price" type="text" className=" w-25"/><label>Tk </label>
                    <h3 className="mt-3 text-danger">Farco+</h3>
                    <label>Qty-: </label>
                    <input id="FarcoPlusQty" type="text" className=" w-25"/> <label>Price-: </label><input id="FarcoPlus_Price" type="text" className=" w-25"/><label>Tk </label>

                    <h3 className="mt-3 text-danger">Comments</h3>
                    <input id="txtComments" type="text" className=" w-75"/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="success" onClick={saveOrder} >
                        Save Order
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>





    );

}