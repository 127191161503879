import {HashRouter, NavLink, Route, Routes} from "react-router-dom";
import HomePage from "../Pages/HomePage";
import AccountPage from "../Pages/AccountPage";
import GodownPage from "../Pages/GodownPage";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import LoginPage from "../Pages/LoginPage";
import SalesPage from "../Pages/SalesPage";
import DeliveryPage from "../Pages/DeliveryPage";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import BazarPage from "../Pages/BazarPage";
import CustomerPage from "../Pages/CustomerPage";
import OrderPage from "../Pages/OrderPage";
import ReportPage from "../Pages/ReportPage";
import CoilCuttingPage from "../Pages/CoilCuttingPage";
import CoilDryingPage from "../Pages/CoilDryingPage";
import ProductionPage from "../Pages/ProductionPage";
import RmPage from "../Pages/RmPage";
import RmReceivedPage from "../Pages/RmReceivedPage";
import UserProfilePage from "../Pages/UserProfilePage";
import ChemicalPage from "../Pages/ChemicalPage";
import EmployeePage from "../Pages/EmployeePage";
import InvoicePage from "../Pages/InvoicePage";



export default function AppRoute(){

    let state=useSelector((state)=> state);
    const [UserRole, setUserRole] = useState(state);



    const isAdmin = () => state.access === 'admin';
    const isAccounts = () => state.access === 'accounts';
    const isSR = () => state.access === 'sr';
    const isSM = () => state.access === 'sm';
    const isCM = () => state.access === 'cm';
    const isProduction = () => state.access === 'production';
    const isPack = () => state.access === 'pack';
    const isStock = () => state.access === 'stock';
    const isHR = () => state.access === 'hr';
    const isRM = () => state.access === 'rm';
    const isWorker = () => state.access === 'worker';
    const isUser = () => state.access === 'user';

console.log()
    return(

        <HashRouter >



            <Routes>

                <Route path="/" element={<LoginPage/>}></Route>
                <Route path="/home" element={<HomePage/>}></Route>
                {
                    isAdmin() &&(
                    <>
                        <Route path="/account" element={<AccountPage/>}></Route>
                        <Route path="/bazar" element={<BazarPage/>}></Route>
                        <Route path="/chemical" element={<ChemicalPage/>}></Route>
                        <Route path="/cutting" element={<CoilCuttingPage/>}></Route>
                        <Route path="/drying" element={<CoilDryingPage/>}></Route>
                        <Route path="/customer" element={<CustomerPage/>}></Route>
                        <Route path="/delivery" element={<DeliveryPage/>}></Route>
                        <Route path="/employee" element={<EmployeePage/>}></Route>
                        <Route path="/godown" element={<GodownPage/>}></Route>
                        <Route path="/invoice" element={<InvoicePage/>}></Route>
                        <Route path="/" element={<LoginPage/>}></Route>
                        <Route path="/order" element={<OrderPage/>}></Route>
                        <Route path="/packing" element={<ProductionPage/>}></Route>
                        <Route path="/report" element={<ReportPage/>}></Route>
                        <Route path="/rm" element={<RmPage/>}></Route>
                        <Route path="/rmrecived" element={<RmReceivedPage/>}></Route>
                        <Route path="/sales" element={<SalesPage/>}></Route>
                        <Route path="/profile" element={<UserProfilePage/>}></Route>
                    </>
                    )
                }
                {isAccounts() &&(
                        <>

                            <Route path="/account" element={<AccountPage/>}></Route>
                            <Route path="/profile" element={<UserProfilePage/>}></Route>
                             </> )}
                {isSR() &&(
                    <>

                        <Route path="/bazar" element={<BazarPage/>}></Route>
                        <Route path="/customer" element={<CustomerPage/>}></Route>
                        <Route path="/order" element={<OrderPage/>}></Route>
                        <Route path="/report" element={<ReportPage/>}></Route>
                        <Route path="/profile" element={<UserProfilePage/>}></Route>
                    </> )}
                {isSM() &&(
                    <>
                        <Route path="/sales" element={<SalesPage/>}></Route>
                        <Route path="/bazar" element={<BazarPage/>}></Route>
                        <Route path="/customer" element={<CustomerPage/>}></Route>
                        <Route path="/order" element={<OrderPage/>}></Route>
                        <Route path="/report" element={<ReportPage/>}></Route>
                        <Route path="/profile" element={<UserProfilePage/>}></Route>
                    </> )}
                {isCM() &&(
                    <>
                        <Route path="/chemical" element={<ChemicalPage/>}></Route>
                        <Route path="/profile" element={<UserProfilePage/>}></Route>
                    </> )}
                {isProduction() &&(
                    <>
                        <Route path="/cutting" element={<CoilCuttingPage/>}></Route>
                        <Route path="/drying" element={<CoilDryingPage/>}></Route>
                        <Route path="/profile" element={<UserProfilePage/>}></Route>
                    </> )}
                {isPack() &&(
                    <>
                        <Route path="/packing" element={<ProductionPage/>}></Route>
                        <Route path="/profile" element={<UserProfilePage/>}></Route>

                    </> )}

                {isStock() &&(
                    <>
                        <Route path="/delivery" element={<DeliveryPage/>}></Route>
                        <Route path="/godown" element={<GodownPage/>}></Route>
                        <Route path="/profile" element={<UserProfilePage/>}></Route>
                    </> )}

                {isHR() &&(
                    <>
                        <Route path="/employee" element={<EmployeePage/>}></Route>
                        <Route path="/profile" element={<UserProfilePage/>}></Route>
                    </> )}
                {isRM() &&(
                    <>
                        <Route path="/rm" element={<RmPage/>}></Route>
                        <Route path="/rmrecived" element={<RmReceivedPage/>}></Route>
                        <Route path="/profile" element={<UserProfilePage/>}></Route>
                    </> )}
                {isUser() &&(
                    <>
                        <Route path="/profile" element={<UserProfilePage/>}></Route>
                    </> )}
                {isWorker() &&(
                    <>
                        <Route path="/profile" element={<UserProfilePage/>}></Route>
                    </> )}

                <Route path="/*" element={<LoginPage/>}></Route>

            </Routes>
        </HashRouter>


    );
}


