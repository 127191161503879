import {Fragment, useEffect, useState} from "react";
import {Button, Col, Container, Nav, Row} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import DatePicker from "react-datepicker";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import {useSelector} from "react-redux";
import AppUtility from "../../RestAPI/AppUtility";

export default function Delivery(props){
    let state=useSelector((state)=> state);

    const [Dealer, setDealer]=useState([]);
    const getDealer=()=>{
        let jsonObject={"sql":"select Id, Name, Address, Mobile from Dealer"};

        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{setDealer(result)}).catch(error=>{cogoToast.error(error);});
    }

    useEffect(() => {
        getDealer();
    }, []);



    let ColumnsDemandDetails=[
        {dataField: 'Id', text: 'Order_No', sort: true},
        {dataField: 'Entry_Date', text: 'Date', sort: true},
        {dataField: 'Dealer', text: 'Dealer', sort: true},
        {dataField: 'Farco', text: 'Farco', sort: true},
        {dataField: 'Farco_Plus', text: 'Farco+', sort: true},
        {dataField: 'Beema', text: 'Beema', sort: true},
        {dataField: 'Beema_Plus', text: 'Beema+ ', sort: true},
        {dataField: 'Total_Carton', text: 'TQ', sort: true},
        {dataField: 'Total_Price', text: 'TP', sort: true},

    ]
    let ColumnsDeliveryDetailes=[
        {dataField: 'Entry_date', text: 'Delivery_Date', sort: true},
        {dataField: 'Order_No', text: 'Order_No', sort: true},
        {dataField: 'id', text: 'Deivery_No', sort: true},
        {dataField: 'Dealer', text: 'Dealer', sort: true},
        {dataField: 'Farco', text: 'Farco', sort: true},
        {dataField: 'Farco_Plus', text: 'Farco+', sort: true},
        {dataField: 'Beema', text: 'Beema', sort: true},
        {dataField: 'Beema_Plus', text: 'Beema+ ', sort: true},
        {dataField: 'TQ', text: 'TQ', sort: true},
        {dataField: 'TP', text: 'TP', sort: true},

    ]
    let ColumnsDeliverySummary=[
        {dataField: 'Farco', text: 'Farco', sort: true},
        {dataField: 'Farco_Plus', text: 'Farco+', sort: true},
        {dataField: 'Beema', text: 'Beema', sort: true},
        {dataField: 'Beema_Plus', text: 'Beema+ ', sort: true},
        {dataField: 'TQ', text: 'TQ', sort: true},
        {dataField: 'TP', text: 'TP', sort: true},
    ]
    const [ColumnDelivery, setColumnDelivery]=useState([{}]);

    const [StartDateDeliveryFrom, setStartDateDeliveryFrom] = useState(new Date());
    const [StartDateDeliveryTo, setStartDateDeliveryTo] = useState(new Date());
    const [StartDateDetailsFrom, setStartDateDetailsFrom] = useState(new Date());
    const [StartDateDetailesTo, setStartDateDetailesTo] = useState(new Date());
    const [totalStock, setTotalStock]=useState('Total Stock')
    const [Address, setAddress]=useState("");
    const [Mobile, setMobile]=useState("");
    const [DataDelivery, setDataDelivery]=useState([]);
    const [DataDemand, setDataDemand]=useState([]);

    const saveDelivery=()=>{
        const cmbDealerName = document.getElementById("cmbDealerName");
        const DealerName = cmbDealerName.options[cmbDealerName.selectedIndex].text;
        const Farco=document.getElementById("txtFarco").value;
        const FarcoPlus=document.getElementById("txtFarcoPlus").value;
        const Beema=document.getElementById("txtBeema").value;
        const BeemaPlus=document.getElementById("txtBeemaPlus").value;
        const TotalPrice=document.getElementById("txtTotalPrice").value;
        const OrderNo=document.getElementById("txtOrderNo").value;
        const Comments=document.getElementById("txtComments").value;
        const User_Name=state.name;
        const TotalQuantity=document.getElementById("txtTotalQty").value;
        let jsonObjectUpdate={
                "sql":"UPDATE Sales_Demand SET Status='Yes' WHERE Id='"+OrderNo+"'"};

        let jsonObject={
                "sql":"INSERT INTO `Delivery`(`Id`,`Order_No`, `Dealer`, `Address`, `Farco`, `Farco_Plus`, `Beema`, `Beema_Plus`,`TQ`, `TP`, `Entry_User`, `Entry_date`) VALUES" +
                    " (NULL,'"+OrderNo+"','"+DealerName+"','"+Address+"','"+Farco+"','"+FarcoPlus+"','"+Beema+"','"+BeemaPlus+"','"+TotalQuantity+"','"+TotalPrice+"','"+User_Name+"',current_timestamp())"
        };


                if(cmbDealerName.selectedIndex==0){cogoToast.warn("Please select Delear Name")}else{
                    if(Farco==""){cogoToast.warn("Please Insert Farco Qty")}else{
                    if(FarcoPlus==""){cogoToast.warn("Please Insert Farco+ Qty")}else{
                    if(Beema==""){cogoToast.warn("Please Insert Beema Qty")}else{
                    if(BeemaPlus==""){cogoToast.warn("Please Insert Beema+ Qty")}else{
                    if(TotalPrice==""){cogoToast.warn("Please Insert Total Price")}else{
                    if(OrderNo==""){cogoToast.warn("Please Insert Order No")}else{
                    if(Comments==""){cogoToast.warn("Please Insert Comments")}else{
                        RestClient.PostRequest(AppUrl.savesalesDemand,jsonObject).then(result=>{
                            RestClient.PostRequest(AppUrl.savesalesDemand,jsonObjectUpdate).then(result=>{ }).catch(error=>{cogoToast.error(error);});
                            cogoToast.success("Sales Demand Saved Successfully");
                            let Number="01789307792"
                            let sms="Delivered to : "+DealerName+" Farco: "+Farco+" Farco+: "+FarcoPlus+" Beema: "+Beema+" Beema+: "+BeemaPlus+" Comments: "+Comments+" Sent by "+User_Name
                            AppUtility.sendSMS(sms,Number)

                        }).catch(error=>{
                            cogoToast.error(error);
                        });
                    }}}}}}}
                }}
    const getDemandDetails=()=>{
        let DateDetailesFrom= AppUtility.getDate(StartDateDetailsFrom);
        let DateDetailesTo= AppUtility.getDate(StartDateDetailesTo);

        let jsonObject=
            {
                "sql":"select Id,Dealer,Farco, Farco_Plus,Beema, Beema_Plus,Entry_Date, Total_Carton,Total_Price from Sales_Demand where Entry_Date Between '"+DateDetailesFrom+"' and '"+DateDetailesTo+"' and Status='No' "
            };


            if(StartDateDetailsFrom==null){cogoToast.error('Please Select Date From');}else{
                if(StartDateDetailesTo==null){cogoToast.error('Please Select Date To');}else{

                    RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                        setDataDemand( result)

                    }).catch(error=>{
                        cogoToast.error(error);
                        setDataDemand([])
                    });

                }}

    }
    const getDelivery=()=>{

        let DateDeliveryFrom= AppUtility.getDate(StartDateDeliveryFrom);
        let DateDeliveryTo= AppUtility.getDate(StartDateDeliveryTo);
        const DeliveryReportIndex = document.getElementById("cmbDeliveryReport").selectedIndex;
        let jsonObject="";
        if(DeliveryReportIndex==0){
            setColumnDelivery(ColumnsDeliverySummary)
            jsonObject={"sql":"select Sum(Farco)AS Farco,Sum(Farco_Plus)AS Farco_Plus,Sum(Beema)AS Beema,Sum(Beema_Plus)AS Beema_Plus,Sum(TQ)AS TQ, Sum(TP)AS TP from Delivery where Entry_date Between '"+DateDeliveryFrom+"' and '"+DateDeliveryTo+"' "};



        }else{
            setColumnDelivery(ColumnsDeliveryDetailes)
            jsonObject={"sql":"select id, Order_No, Dealer,Farco, Farco_Plus,Beema, Beema_Plus,Entry_date,TQ,TP from Delivery where Entry_date Between '"+DateDeliveryFrom+"' and '"+DateDeliveryTo+"' "};

        }



        if(StartDateDetailsFrom==null){cogoToast.error('Please Select Date From');}else{
            if(StartDateDetailesTo==null){cogoToast.error('Please Select Date To');}else{

                RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                        if(result!=1){ cogoToast.success("Data found");setDataDelivery( result)}else{cogoToast.warn("Data not found");}

                }).catch(error=>{
                    cogoToast.error(error);
                    setDataDelivery([])
                });

            }}

    }

    const getDelaerDetailes=()=>{
    const typeAccountsDetailes = document.getElementById("cmbDealerName");
    const typeAccountsDetailesValue = typeAccountsDetailes.options[typeAccountsDetailes.selectedIndex].text;
    if(typeAccountsDetailes.options[typeAccountsDetailes.selectedIndex].value==0){
        setMobile("");setAddress("")
    }else{
        let jsonObject=
            {
                "sql":"select Address,Mobile from Dealer where Name='"+typeAccountsDetailesValue+"' "
            };
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
            setAddress(result[0]["Address"])
            setMobile(result[0]["Mobile"])
        }).catch(error=>{
            cogoToast.error(error);
        });
    }




}

    return(
        <Fragment>
            <div className="purchaseDiv text-center">
                <div className="overflow-scroll">
                <Nav className="navItem float-end"><NavLink to="/home"><Button variant="warning" >Close</Button></NavLink></Nav>

                <h3>Delivery Entry</h3>
                <hr/>

                <Container>
                    <Row>

                        <Col lg={4} md={3} sm={12}>
                            <div className="purchaseDiv text-center">
                                <Row >
                                    <Col lg={2} sm={6}><label className="customfontsize m-4">Name</label></Col>
                                    <Col lg={10} sm={6}>
                                        <select className="m-4 w-75 float-right" id="cmbDealerName" onChange={getDelaerDetailes} >
                                            <option value="0">-Select Type-</option>
                                            {


                                                Dealer.map((ctr,index)=>{
                                                    return (
                                                        <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                                    )
                                                })

                                            }
                                        </select>

                                    </Col>
                                </Row>
                                <Row className="mr-3">
                                    <Col lg={4} sm={6}>  <label className="customfontsize w-75">Address</label></Col>
                                    <Col lg={8} sm={6}> <label id="lblAddress" className="customfontsize w-75">{Address}</label></Col>

                                </Row>
                                <Row className="mt-4">
                                    <Col lg={4} sm={6}>  <label className="customfontsize w-75">Mobile</label></Col>
                                    <Col lg={8} sm={6}> <label id="lblMobile" className="customfontsize w-75">{Mobile}</label></Col>

                                </Row>
                                <Row className="mt-4">

                                    <Col lg={3} sm={6}>  <label className="customfontsize text-danger w-75">Farco</label></Col>
                                    <Col lg={3} sm={6}> <input id="txtFarco" type="text" className="w-100 customfontsize"/></Col>
                                    <Col lg={3} sm={6}>  <label className="customfontsize text-danger w-75">Farco+</label></Col>
                                    <Col lg={3} sm={6}> <input id="txtFarcoPlus" type="text" className="w-100 customfontsize"/></Col>

                                </Row>
                                <Row className="mt-2">
                                    <Col lg={3} sm={6}>  <label className="customfontsize text-danger w-75">Beema</label></Col>
                                    <Col lg={3} sm={6}> <input id="txtBeema" type="text" className="w-100 customfontsize"/></Col>
                                    <Col lg={3} sm={6}>  <label className="customfontsize text-danger w-75">Beema+</label></Col>
                                    <Col lg={3} sm={6}> <input id="txtBeemaPlus" type="text" className="w-100 customfontsize"/></Col>

                                </Row>
                                <Row className="mt-2">
                                    <Col lg={3} sm={6}>  <label className="customfontsize text-success w-75">TQ</label></Col>
                                    <Col lg={3} sm={6}> <input id="txtTotalQty" type="text" className="w-100 customfontsize"/></Col>
                                    <Col lg={3} sm={6}>  <label className="customfontsize text-success w-75">TP</label></Col>
                                    <Col lg={3} sm={6}> <input id="txtTotalPrice" type="text" className="w-100 customfontsize"/></Col>

                                </Row>

                                <Row className="mt-2">
                                    <Col lg={3} sm={12}>  <label className="customfontsize text-success w-75">Oder No</label></Col>
                                    <Col lg={3} sm={12}> <input id="txtOrderNo" type="text" className="w-100 customfontsize"/></Col>
                                    <Col lg={5} sm={12}>  <label className="customfontsize text-success w-75">Comments</label></Col>

                                </Row>
                                <Row >

                                    <textarea id="txtComments" className="customfontsize m-4 w-75"></textarea>
                                </Row>

                                <Row >
                                    <Col lg={12} md={6} sm={12}><Button onClick={saveDelivery} variant="success" className="w-100 mt-2">Save</Button></Col>

                                </Row>
                            </div>
                        </Col>
                        <Col lg={4} md={3} sm={12}>
                            <div className="purchaseDiv text-center "> <label className="mb-2 text-bg-warning">Daily sales Demand Report</label>
                                <Row>
                                    <Col lg={3}>
                                        <label>Date From</label>
                                    </Col>
                                    <Col lg={4}>
                                        <DatePicker selected={StartDateDetailsFrom}
                                                    onChange={(date) =>setStartDateDetailsFrom (date)}
                                                    dateFormat='dd-MM-yyyy'
                                                    seletable
                                                    maxDate={new Date()}
                                                    isClearable
                                                    showYearDropdown
                                                    scrollableMonthYearDropdown
                                        />
                                    </Col>

                                </Row>
                                <Row className="mt-2">
                                    <Col lg={3}>
                                        <label >Date To</label>
                                    </Col>
                                    <Col lg={3}>

                                        <DatePicker selected={StartDateDetailesTo}
                                                    onChange={(date) => setStartDateDetailesTo(date)}
                                                    dateFormat='dd-MM-yyyy'
                                                    seletable
                                                    maxDate={new Date()}
                                                    isClearable
                                                    showYearDropdown
                                                    scrollableMonthYearDropdown
                                        />
                                    </Col>

                                </Row>


                                <Row >
                                    <Col lg={6} md={6} sm={12}><Button onClick={getDemandDetails}  variant="success" className="w-75 m-4">Report</Button></Col>
                                </Row>

                                <div id="ddlDiv" className="overflow-scroll ">
                                    <div className="tableDiv">
                                        <BootstrapTable  keyField='id' data={DataDemand} columns={ColumnsDemandDetails} > </BootstrapTable>
                                    </div>

                                </div>

                            </div>

                        </Col>
                        <Col lg={4} md={3} sm={12}>
                            <div className="purchaseDiv text-center "> <label className="mb-2 text-danger">Daily Delivery Report</label>
                                <Row >

                                    <Col lg={12} sm={12}>
                                        <select className="m-4 w-75 float-right" id="cmbDeliveryReport"  >
                                            <option value="0">Delivery Summery</option>
                                            <option value="0">Delivery Detailes</option>

                                        </select>

                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={3}>
                                        <label>Date From</label>
                                    </Col>
                                    <Col lg={4}>
                                        <DatePicker selected={StartDateDeliveryFrom}
                                                    onChange={(date) =>setStartDateDeliveryFrom (date)}
                                                    dateFormat='dd-MM-yyyy'
                                                    seletable
                                                    maxDate={new Date()}
                                                    isClearable
                                                    showYearDropdown
                                                    scrollableMonthYearDropdown
                                        />
                                    </Col>

                                </Row>
                                <Row className="mt-2">
                                    <Col lg={3}>
                                        <label >Date To</label>
                                    </Col>
                                    <Col lg={3}>

                                        <DatePicker selected={StartDateDeliveryTo}
                                                    onChange={(date) =>setStartDateDeliveryTo(date)}
                                                    dateFormat='dd-MM-yyyy'
                                                    seletable
                                                    maxDate={new Date()}
                                                    isClearable
                                                    showYearDropdown
                                                    scrollableMonthYearDropdown
                                        />
                                    </Col>





                                </Row>


                                <Row >
                                    <Col lg={6} md={6} sm={12}><Button  onClick={getDelivery} variant="success" className="w-75 m-4">Report</Button></Col>
                                </Row>

                                <div id="ddlDiv" className="overflow-scroll ">
                                    <div className="tableDiv">
                                        <BootstrapTable  keyField='id' data={DataDelivery} columns={ColumnDelivery} > </BootstrapTable>
                                    </div>

                                </div>

                            </div>

                        </Col>

                    </Row>

                </Container>
                </div>
            </div>
        </Fragment>
    )
}