import {Fragment} from "react";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Invoice from "../components/Invoice/Invoice";

export default function InvoicePage(){

    return(
        <Fragment>
            <TopNavigation />
            <Invoice/>
        </Fragment>
    )
}