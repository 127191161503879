import RestClient from "./RestClient";
import AppUrl from "./AppUrl";
import cogoToast from "cogo-toast";
import {useSelector} from "react-redux";

class AppUtility{

    static sendSMS=(sms,number)=>{
        const date=new Date();
        let day=date.getDate();
        let month=date.getMonth()+1;
        let year=date.getFullYear();
        let h=date.getHours();
        let m=date.getMinutes();
        let s=date.getSeconds();

        let time= day+"/"+month+"/"+year+" "+h+":"+m+":"+s;


        let jsonObject=
            {
                "api_key" :"Y9FCgQ7ADxvFfwdAL2qm",
                "senderid" : "8809617611096",
                "number" : number,
                "message" :sms+" "+time,

            };

        RestClient.PostRequest(AppUrl.sendSms,jsonObject).then(result=>{
            if(result["response_code"]==202){cogoToast.success(result["success_message"])}else{
                cogoToast.error("SMS not sent")
            }


        });



    }
    static getDate=(date)=>{
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        let sazzad=[year, month, day].join('-');
        return sazzad;
    }


}
export default AppUtility;