import {Fragment, useState} from "react";
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import Form from 'react-bootstrap/Form';
import cogoToast from "cogo-toast";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import BootstrapTable from "react-bootstrap-table-next";
export default function NewEmployee(){
    let state=useSelector((state)=> state);
    const[Sex, setSex]=useState("Male");
    const[Data, setData]=useState([]);
    const[ID, setID]=useState();
    const[Name, setName]=useState();
    const[Designation, setDesignation]=useState();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let Columns=[
        {dataField: 'id', text: 'ID', sort: true},
        {dataField: 'Type', text: 'Designation ', sort: true},
        {dataField: 'Name', text: 'Name', sort: true},
        {dataField: 'Father', text: 'Father', sort: true},
        {dataField: 'Sex', text: 'Gender', sort: true},
        {dataField: 'Address', text: 'Address', sort: true},
        {dataField: 'Mobile', text: 'Mobile', sort: true},
        {dataField: 'NID', text: 'NID', sort: true},
    ]
    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setName(row["Name"]);
            setDesignation(row["Type"]);
            setID(row["id"]);

            handleShow()
        }
    }

    const getEmployee=()=>{

        const EmployeeType = document.getElementById("cmbEmployeeType");
        const EmployeeTypeValue = EmployeeType.options[EmployeeType.selectedIndex].text;




       let jsonObject={"sql":"Select * From Employee"};




                                RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{setData(result)}).catch(error=>{cogoToast.error(error);});






    }

    const deleteEmployee=()=>{

        let jsonObject={"sql":"DELETE FROM `Employee` WHERE id='"+ID+"' "};




        RestClient.PostRequest(AppUrl.deleteData,jsonObject).then(result=>{
               if(result==1){cogoToast.success("Data deleted successfully")
                   RestClient.PostRequest(AppUrl.getData,{"sql":"Select * From Employee"}).then(result=>{setData(result)}).catch(error=>{cogoToast.error(error);});
                    handleClose();

               }else{
                   cogoToast.error("Data not delete")
               }
        }).catch(error=>{
            cogoToast.error(error);

        });






    }
    const saveEmployee=()=>{

        const EmployeeType = document.getElementById("cmbEmployeeType");
        const EmployeeTypeValue = EmployeeType.options[EmployeeType.selectedIndex].text;

        const Name=document.getElementById("txtName").value;
        const Father=document.getElementById("txtFather").value;
        const Address=document.getElementById("txtAddress").value;
        const Mobile=document.getElementById("txtMobile").value;
        const NID=document.getElementById("txtNid").value;
        const Comments=document.getElementById("txtComments").value;


        let jsonObject={"sql":"INSERT INTO `Employee` (`id`, `Type`, `Name`, `Father`, `Sex`, `Address`, `Mobile`, `NID`, `Comments`, `Entry_Date`)" +
                " VALUES (NULL, '"+EmployeeTypeValue+"', '"+Name+"', '"+Father+"', '"+Sex+"', '"+Address+"', '"+Mobile+"', '"+NID+"', '"+Comments+"', current_timestamp()) "};

        console.log(jsonObject)

        if(EmployeeType.selectedIndex==0){cogoToast.error("Please select Type")}else{
            if(Name==""){cogoToast.error("Please select Name")}else{
                if(Sex==""){cogoToast.error("Please select Sex")}else{
                    if(Father==""){cogoToast.error("Please select Father")}else{
                        if(Address==""){cogoToast.error("Please select Address")}else{
                            if(Mobile==""){cogoToast.error("Please select Mobile")}else{
                                if(NID==""){cogoToast.error("Please select NID")}else{
                                    if(Comments==""){cogoToast.error("Please select Comments")}else{


                                        RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{
                                            if(result==1){ cogoToast.success("Data Saved");}else{cogoToast.warn("Data not Saved");}

                                        }).catch(error=>{
                                            cogoToast.error(error);

                                        });

                                    }}}}}}}}




    }

    return(
        <Fragment>
               <Container>
                <Row>
                    <Col lg={4} sm={12}>
                        <Row className="mt-2 text-justify">
                            <Col lg={6}><label className="customfontsize">Employee Type</label></Col>
                            <Col lg={6}>
                                <select className=" w-100 float-right" id="cmbEmployeeType"  >
                                <option value="0">-Select Type-</option>
                                {
                                    state.hrCategory.map((ctr,index)=>{
                                        return (
                                            <option key={index} value={ctr.id}>{ctr.Category}</option>
                                        )
                                    })

                                }

                            </select>
                            </Col>

                        </Row>
                        <Row className="mt-2 text-justify">
                            <Col lg={6}><label className="customfontsize">Name</label></Col>
                            <Col lg={6}> <input id="txtName" type="text" /></Col>

                        </Row>
                        <Row className="mt-2 text-justify">
                            <Col lg={6}><label className="customfontsize">Father/Husband</label></Col>
                            <Col lg={6}> <input id="txtFather" type="text" /></Col>

                        </Row>
                        <Row className="mt-2 text-justify">
                            <Col lg={6}><label className="customfontsize">Sex</label></Col>
                            <Col lg={6}>
                                <Form.Check
                                    inline
                                    label="Male"
                                    name="group1"
                                    type="radio"
                                    id="rbMale"
                                    defaultChecked={true}
                                    onClick={()=>{
                                        setSex("Male")
                                    }}
                                />
                                <Form.Check
                                inline
                                label="Female"
                                name="group1"
                                type="radio"
                                id="rbFemale"

                                onClick={()=>{
                                   setSex("Female")
                                }}
                            />
                            </Col>

                        </Row>
                        <Row className="mt-2 text-justify">
                            <Col lg={6}><label className="customfontsize">Address</label></Col>
                            <Col lg={6}> <input id="txtAddress" type="text" /></Col>

                        </Row>
                        <Row className="mt-2 text-justify">
                            <Col lg={6}><label className="customfontsize">Mobile</label></Col>
                            <Col lg={6}> <input id="txtMobile" type="text" /></Col>

                        </Row>
                        <Row className="mt-2 text-justify">
                            <Col lg={6}><label className="customfontsize">NID</label></Col>
                            <Col lg={6}> <input id="txtNid" type="text" /></Col>

                        </Row>
                        <Row className="mt-2 text-justify">
                            <Col lg={6}><label className="customfontsize">Comments</label></Col>
                            <Col lg={6}> <textarea id="txtComments"></textarea></Col>

                        </Row>
                        <Row className="mt-2 text-justify">
                            <Col lg={6}> </Col>
                            <Col lg={6}><Button onClick={saveEmployee} className="w-100">Save</Button></Col>


                        </Row>
                    </Col>
                    <Col lg={8} sm={12}>
                        <div className="purchaseDiv text-center">
                            <label className="text-danger">Getting Chemical No</label>
                            <Row >
                                <Col lg={2} md={6} sm={12}><label>Type</label></Col>
                                <Col lg={3} md={6} sm={12}><select className=" w-100 float-right" id="cmbEmployeeType"  >
                                    <option value="0">-Select Type-</option>
                                    {
                                        state.hrCategory.map((ctr,index)=>{
                                            return (
                                                <option key={index} value={ctr.id}>{ctr.Category}</option>
                                            )
                                        })

                                    }

                                </select><Button onClick={getEmployee}  className="w-100 mt-4"  >Search</Button></Col>

                            </Row>

                            <div className="tableDiv overflow-scroll">
                                <BootstrapTable  keyField='id' data={Data} columns={Columns} selectRow={selectRow} > </BootstrapTable>
                            </div>





                        </div>
                    </Col>
                </Row>

               </Container>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Do you want to delete?</Modal.Title>


                </Modal.Header>
                <Modal.Body>

                    <label className="w-25">ID:</label><input value={ID} disabled={true} className="w-25"/><br/>
                    <label className="w-25 mt-2">Name:</label><input value={Name} disabled={true} className="w-25"/><br/>
                    <label className="w-25 mt-2">Designation:</label><input value={Designation} disabled={true} className="w-25"/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="success" onClick={deleteEmployee} >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}