import {Fragment, useState} from "react";
import {NavLink} from "react-router-dom";
import {Button, Col, Container, Modal, Nav, Row} from "react-bootstrap";
import cogoToast from "cogo-toast";

import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import error from "../../asset/image/error.svg";
import loading from "../../asset/image/loader.svg";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useSelector} from "react-redux";
import AppUtility from "../../RestAPI/AppUtility";
export default function Expenditure(){
    let state=useSelector((state)=> state);

    const [BtnSave, setBtnsave]=useState("success")
    const [BtnSummary, setBtnSummary]=useState("success")
    const [Btndetailes, setBtnDetailes]=useState("success")
    const [BtnBalance, setBtnBalance]=useState("success")

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () =>{
        const typeAccounts = document.getElementById("ddlType");
        const typeAccountsValue = typeAccounts.options[typeAccounts.selectedIndex].text;

        const Amount=document.getElementById("txtAmount").value;
        const Comments=document.getElementById("txtComments").value;
        if(typeAccounts.selectedIndex==0){cogoToast.error('Please Select Type');typeAccounts.focus()}else{
            if(Amount==""){cogoToast.error('Amount  Empty');}else{
                if(Comments==""){cogoToast.error('Comments  Empty');}else{
                    setShow(true);
                }}}


    }



   const getDetails=()=>{

       const typeAccountsDetailes = document.getElementById("typeAccountsDetailes");
       const typeAccountsDetailesValue = typeAccountsDetailes.options[typeAccountsDetailes.selectedIndex].text;

       let DateDetailesFrom= AppUtility.getDate(startDateDetailsFrom);
       let DateDetailesTo= AppUtility.getDate(startDateDetailesTo);

       let jsonObject=
           {
               "sql":"select Amount, Entry_Date, Comments,Type,Entry_User  from Accounts where Type='"+typeAccountsDetailesValue+"' and Entry_Date between '"+DateDetailesFrom+"' and '"+DateDetailesTo+"' "
           };

       if(typeAccountsDetailes.selectedIndex==0){cogoToast.error('Please Select Type');typeAccountsDetailes.focus()}else{
           if(startDateDetailsFrom==null){cogoToast.error('Please Select Date From');}else{
               if(startDateDetailesTo==null){cogoToast.error('Please Select Date To');}else{


                        setBtnDetailes("secondary")
                        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{

                            if(result==""){
                                cogoToast.error("No Data Found")
                                setBtnDetailes("success")
                                setData( result)
                            }else{
                                setBtnDetailes("success")
                                setData( result)
                            }
                            }).catch(error=>{
                                cogoToast.error(error);
                            setBtnDetailes("success")
                            setData( [])
                                     });

               }}}

    }
    let Columns=[
        {dataField: 'Entry_Date', text: 'Entry_Date', sort: true},
        {dataField: 'Amount', text: 'Amount', sort: true},
        {dataField: 'Comments', text: 'Comments', sort: true},
        {dataField: 'Type', text: 'Type', sort: true},
        {dataField: 'Entry_User', text: 'Entry_User', sort: true},

    ]
    const getAmount=()=>{
        const typeAccountsSummary = document.getElementById("typeAccountsSummary");
        const typeAccountsSummaryValue = typeAccountsSummary.options[typeAccountsSummary.selectedIndex].text;

           let DateSummeryFrom= AppUtility.getDate(startDateSummeryFrom);
           let DateSummeryTo= AppUtility.getDate(startDateSummeryTo);



        let jsonObject=
            {
                "sql":"select sum(Amount) As Amount from Accounts where Type='"+typeAccountsSummaryValue+"' and Entry_Date between '"+DateSummeryFrom+"' and '"+DateSummeryTo+"' "
            };

        if(typeAccountsSummary.selectedIndex==0){cogoToast.error('Please Select Type');typeAccountsSummary.focus()}else{
            if(startDateSummeryFrom==null){cogoToast.error('Please Select Date From');}else{
                if(startDateSummeryTo==null){cogoToast.error('Please Select Date To');}else{
                        setBtnSummary("secondary")
                    RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                        if(result[0]["Amount"]==null){document.getElementById("lblAmount").innerText="No Amount found";
                        cogoToast.error("No Data Found")
                            setBtnSummary("success")
                        }else{
                            document.getElementById("lblAmount").innerText=result[0]["Amount"];
                            cogoToast.success("Data Found")
                            setBtnSummary("success")
                        }
                    }).catch(error=>{
                        cogoToast.error(error); setBtnSummary("success")
                    });
                }}}
    }
    const getBalance=()=>{
                    setBtnBalance("secondary")
        let jsonObject={"sql":"SELECT sum(Amount)-(SELECT sum(Amount) FROM `Accounts` WHERE Type='Expenditure') As Total FROM `Accounts` WHERE Type='Sales' "};
                    RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                        if(result==""){document.getElementById("lblBalance").innerText="No Balance found";
                            cogoToast.error("No Balance Found")
                            setBtnBalance("success")
                        }else{
                            document.getElementById("lblBalance").innerText=result[0]["Total"];
                            cogoToast.success("Data Found")
                            setBtnBalance("success")
                        }
                    }).catch(error=>{
                        cogoToast.error(error);
                        setBtnBalance("success")
                    });
    }

    const saveAmount=()=>{
        const typeAccounts = document.getElementById("ddlType");
        const typeAccountsValue = typeAccounts.options[typeAccounts.selectedIndex].text;

    const Amount=document.getElementById("txtAmount").value;
    const Comments=document.getElementById("txtComments").value;

        let jsonObject=
            {
                "sql":"Insert into Accounts (Amount, Type,Comments, Entry_User )Values("+Amount+",'"+typeAccountsValue+"','"+Comments+"','"+state.name+"')"
            };

                        RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{
                            if(result==1){
                                cogoToast.success("Data Saved Successfully");
                                let number="01789307792,01737924639,01703199562"
                                let sms=typeAccountsValue+" Amount: "+Amount+" Taka "+"Comments: "+Comments+" by: "+state.name+" sent at"
                                AppUtility.sendSMS(sms,number);
                                handleClose()
                            }else{
                                cogoToast.error("Data not Saved")
                                handleClose()
                            }

                        }).catch(error=>{
                            cogoToast.error(error);
                            handleClose()
                        });
    }

    const [startDateSummeryFrom, setStartDateSummeryFrom] = useState(new Date());
    const [startDateSummeryTo, setStartDateSummeryTo] = useState(new Date());
    const [startDateDetailsFrom, setStartDateDetailsFrom] = useState(new Date());
    const [startDateDetailesTo, setStartDateDetailesTo] = useState(new Date());


    const [Data, setData]=useState([]);


    return(
        <Fragment>

            <div className="purchaseDiv text-center">
                <Nav className="navItem float-end"><NavLink to="/home"><Button variant="warning" >Close</Button></NavLink></Nav>

                <h3>Accounts Entry Form</h3>
                <hr/>

                <Container>
                    <Row>

                        <Col lg={3} md={3} sm={12}>
                            <Row>
                                <label className="customfontsize">Bill Type</label>
                                <select className="float-start w-100" id="ddlType"  >
                                    <option value="0">-Select Type-</option>
                                    <option value="0">Sales</option>
                                    <option value="0">Expenditure</option>
                                </select>
                            </Row>
                            <Row>
                                <label className="customfontsize">Amount</label>
                                <input id="txtAmount" type="text" className="w-100 customfontsize"/>
                            </Row>
                            <Row>

                            </Row>
                            <Row>
                                <label className="customfontsize">Comments</label>
                                <textarea id="txtComments" className="customfontsize"></textarea>
                            </Row>

                            <Row >
                                <Col lg={6} md={6} sm={12}><Button onClick={handleShow} variant={BtnSave} className="w-100 mt-4">Save</Button></Col>
                                <Col  lg={6} md={6} sm={12}><Button  variant="danger" className="w-100 mt-4" >Reset</Button></Col>
                            </Row>

                        </Col>
                        <Col lg={4} md={3} sm={12}>
                            <div className="purchaseDiv text-center"> <label>Accounts Summary</label>

                                    <Row>
                                        <select className="m-4 w-75 float-right" id="typeAccountsSummary"  >
                                            <option value="0">-Select Type-</option>
                                            <option value="0">Sales</option>
                                            <option value="0">Expenditure</option>
                                        </select>
                                    </Row>

                                    <Row>
                                       <Col lg={3}>
                                           <label>Date From</label>
                                       </Col>
                                        <Col lg={4}>
                                            <DatePicker selected={startDateSummeryFrom}
                                                        onChange={(date) => setStartDateSummeryFrom(date)}
                                                        dateFormat='dd-MM-yyyy'
                                                        seletable
                                                        maxDate={new Date()}
                                                        isClearable
                                                        showYearDropdown
                                                        scrollableMonthYearDropdown
                                            />
                                        </Col>

                                    </Row>
                                <Row className="mt-2">
                                    <Col lg={3}>
                                        <label >Date To</label>
                                    </Col>
                                    <Col lg={4}>
                                        <DatePicker selected={startDateSummeryTo}
                                                    onChange={(date) => setStartDateSummeryTo(date)}
                                                    dateFormat='dd-MM-yyyy'
                                                    seletable
                                                    maxDate={new Date()}
                                                    isClearable
                                                    showYearDropdown
                                                    scrollableMonthYearDropdown
                                        />
                                    </Col>

                                </Row>

                                <Row className="mt-4">
                                    <Col lg={5}>
                                        <label  >Total Amount:</label>
                                    </Col>
                                    <Col lg={5} >
                                        <label className="customfontsize " id="lblAmount" >0</label>
                                    </Col>
                                    <Col lg={2}>
                                        <label >Taka</label>
                                    </Col>
                                </Row>

                                    <Row >
                                        <Col lg={6} md={6} sm={12}><Button onClick={getAmount} variant={BtnSummary} className="w-75 m-4">Report</Button></Col>
                                        <Col  lg={6} md={6} sm={12}><Button  variant="danger" className="w-75 mt-4" >Reset</Button></Col>
                                        </Row>

                                <hr />
                                <label>Available Balance</label>

                                <Row className="mt-4">
                                    <Col lg={5}>
                                        <label>Total Balance</label>
                                    </Col>
                                    <Col lg={5} >
                                        <label className="customfontsize " id="lblBalance" ></label>
                                    </Col>
                                    <Col lg={2}>
                                        <label  >Taka</label>
                                    </Col>
                                </Row>
                                <Row >
                                    <Col lg={6} md={6} sm={12}><Button variant={BtnBalance} onClick={getBalance}   className="w-75 m-4">Search Balance</Button></Col>
                                    <Col  lg={6} md={6} sm={12}><Button  variant="danger" className="w-75 mt-4" >Reset</Button></Col>
                                </Row>

                            </div>

                        </Col>
                        <Col lg={5} md={3} sm={12}>
                            <div className="purchaseDiv text-center"> <label>Accounts Detailes Report</label>

                                <Row>
                                    <select className="m-4 w-75 float-right" id="typeAccountsDetailes"  >
                                        <option value="0">-Select Type-</option>
                                        <option value="0">Sales</option>
                                        <option value="0">Expenditure</option>
                                    </select>
                                </Row>

                                <Row>
                                    <Col lg={3}>
                                        <label>Date From</label>
                                    </Col>
                                    <Col lg={4}>
                                        <DatePicker selected={startDateDetailsFrom}
                                                    onChange={(date) =>setStartDateDetailsFrom (date)}
                                                    dateFormat='dd-MM-yyyy'
                                                    seletable
                                                    maxDate={new Date()}
                                                    isClearable
                                                    showYearDropdown
                                                    scrollableMonthYearDropdown
                                        />
                                    </Col>

                                </Row>
                                <Row className="mt-2">
                                    <Col lg={3}>
                                        <label >Date To</label>
                                    </Col>
                                    <Col lg={3}>

                                        <DatePicker selected={startDateDetailesTo}
                                                    onChange={(date) => setStartDateDetailesTo(date)}
                                                    dateFormat='dd-MM-yyyy'
                                                    seletable
                                                    maxDate={new Date()}
                                                    isClearable
                                                    showYearDropdown
                                                    scrollableMonthYearDropdown
                                        />
                                    </Col>





                                </Row>


                                <Row >
                                    <Col lg={6} md={6} sm={12}><Button onClick={getDetails} variant={Btndetailes} className="w-75 m-4">Report</Button></Col>
                                </Row>

                                <div id="ddlDiv" className="overflow-scroll ">
                                    <div className="tableDiv">
                                        <BootstrapTable  keyField='id' data={Data} columns={Columns} > </BootstrapTable>
                                    </div>

                                </div>

                            </div>

                        </Col>
                    </Row>

                </Container>
            </div>

            <Modal
                size="sm"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Do You Want to Save</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Please check before Save.
                </Modal.Body>
                <Modal.Footer>
                    <Button className="w-25" variant="danger" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button className="w-25" variant="success" onClick={saveAmount}>OK</Button>
                </Modal.Footer>
            </Modal>

        </Fragment>
    )
}
